<template>
  <section id="contact" class="bg-white">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8 pt-2 xl:pt-20 pb-20">
      <section-heading :title="$t('contact.title')" line-class="bg-black">
        {{ $t('contact.subtext') }}
      </section-heading>

      <div class="flex flex-col lg:flex-row lg:-space-x-10 min-h-40">

        <div class="ring-gray-300 ring-2 ring-offset-8 h-30 w-full lg:w-1/2 mx-auto rounded my-6 shadow-xl p-4">
          <div class="flex h-full justify-center items-center space-x-20 pr-8 lg:pr-16 pl-8">
            <a href="https://apps.lu" target="_blank">
              <img src="../../assets/apps-logo-new.png" class="w-60" alt="">
            </a>
          </div>
        </div>

        <div
            class="bg-gradient-to-br from-blue-400 to-blue-600 w-full lg:w-1/2 mx-auto rounded shadow-2xl p-6 flex flex-wrap content-center">
          <div class="w-full text-gray-100 font-open-sans">
            <div class="flex h-full lg:justify-center space-x-20">
              <div class="flex-1">
                <div class="text-3xl lg:text-2xl font-bold mb-4">apps.lu by Kirepo</div>
                <span class="text-2xl lg:text-xl font-light">
                  1, Rue de Wormeldange-Haut<br>
                  L-5488 Ehnen
                </span>
              </div>
              <p class="text-right">
                <span class="font-bold font-xl">{{ $t('contact.phone') }}:</span><br> +352 20 21 00 55<br>
                <span class="font-bold font-xl">{{ $t('contact.email') }}:</span><br> info@apps.lu
              </p>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
import SectionHeading from "@/components/partials/SectionHeading";

export default {
  name: "Contact",
  components: {SectionHeading}
}
</script>

<style scoped>

</style>